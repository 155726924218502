import { Component, HostListener, OnInit } from '@angular/core';
// Services
import { ResponsiveService } from './modules/shared/services/responsive/responsive.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  public typeSelected: string;

  constructor(
    private _responsiveService: ResponsiveService,
  ) {
    this.typeSelected = 'ball-fussion';
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.setInitWidth();
  }

  ngOnInit(): void {
    this.setInitWidth();
  }

  private setInitWidth(): void {
    const isSmallView: boolean = (window.innerWidth <= 900);
    this._responsiveService.emitterActionResponsive(isSmallView);
  }  

}
