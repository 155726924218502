import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { StoreService } from "../modules/shared/services/store/store.service";
import { NavigationService } from "../modules/shared/services/navigation/navigation.service";
import Swal from "sweetalert2";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _storeService: StoreService,
    private _navigationService: NavigationService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.headers.get("other-device")) {
          this._storeService.logout();
          Swal.fire({
            icon: "warning",
            title: "¡Seguridad!",
            text: "Ya existe una sesión activa en otro dispositivo.",
            confirmButtonText: "Aceptar",
          }).catch((err) => console.error(err));
          this._navigationService.logout();
        }
        return throwError(() => new Error("test"));
      })
    );
  }
}
