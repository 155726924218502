import { Router, ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";

// Models
import { MenuOption } from "../../models/menu_option";
import { DataHeader } from "../../models/data_header";

// Services
import { StoreService } from "../store/store.service";
import { User, Option } from "../../models/user";

const menuOptions: MenuOption[] = [
  { icon: "circle", text: "Resumen", url: "/dashboard" },
  { icon: "graph-line", text: "Estado de comisiones", url: "/commissions-statements" },
  { icon: "folder", text: "Actas escrutadas", url: "/proceedings" },
  { icon: "e14", text: "E-14C Digitalizados", url: "/e14digitized" },
  {
    icon: "consult-e14",
    text: "Consulta Actas E-14C",
    url: "/consult",
    customIcon: true,
  },
  { icon: "file", text: "Resultado de los Escrutinios", url: "/publishedes" },
  { icon: "graph", text: "Comisiones leídas", url: "/commissions" },
  { icon: "statistics", text: "Votación", url: "/votation" },
  { icon: "alert", text: "Novedades", url: "/news" },
  { icon: "security", text: "Reclamaciones", url: "/claims" },
  { icon: "audit", text: "Auditoría", url: "/audit", customIcon: true },
  { icon: "search", text: "Log de Escrutinio", url: "/scrutiny-log" },
  { icon: "download", text: "Descarga de archivos", url: "/files" },
  {
    icon: "log-icon",
    text: "Log de recepción",
    url: "/logs",
    customIcon: true,
  },
  {
    icon: "commission-files",
    text: "Archivos de comisión",
    url: "/commission-files",
    customIcon: true,
  },
  {
    icon: "initialize",
    text: "Inicializar BD",
    url: "/initialize",
    customIcon: true,
  },
  {
    icon: "release",
    text: "Liberar licencia",
    url: "/release",
    customIcon: true,
  },
  {
    icon: "release-commission",
    text: "Liberar comisión",
    url: "/release-commission",
    customIcon: true,
  },
  {
    icon: "carnet-user",
    text: "Gestión comisiones",
    url: "/management",
    customIcon: true,
  },
  {
    icon: "management-user",
    text: "Gestión usuarios",
    url: "/users",
    customIcon: true,
  },
  {
    icon: "role",
    text: "Gestión roles",
    url: "/role-management",
    customIcon: true,
  },
  {
    icon: "consult-winners",
    text: "Consulta de elegidos",
    url: "/winners",
    customIcon: true,
  },
  { icon: "logout", text: "Salir", url: "/logout" },
];

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private stateHeader$: Subject<DataHeader> = new Subject<DataHeader>();
  private stateMenu$: Subject<boolean | undefined> = new Subject<
    boolean | undefined
  >();

  constructor(
    private _router: Router,
    private _storeService: StoreService,
    private _activatedRoute: ActivatedRoute
  ) {}

  public goTo(url: string): void {
    this._router.navigate([url]);
  }

  public getMenuOptions(): MenuOption[] {
    const myOptions: MenuOption[] = [];
    for (const option of menuOptions) {
      if (this.verifyUrl(option.url)) {
        myOptions.push(option);
      }
    }
    return myOptions;
  }

  public verifyUrl(url: string): boolean {
    let user: User = this._storeService.getUser()!;
    const option: Option | undefined = user.options.find(
      (item: Option) => item.url == url.slice(1)
    );
    return option != undefined;
  }

  public logout(): void {
    this._storeService.logout();
    this.goTo("/login");
  }

  /****************** Functions Emitters ********************************/

  public getSubjectMenu(): Subject<boolean | undefined> {
    return this.stateMenu$;
  }

  public emitterActionMenu(data?: boolean): void {
    this.stateMenu$.next(data);
  }

  public getSubjectHeader(): Subject<DataHeader> {
    return this.stateHeader$;
  }

  public emitterActionHeader(data: DataHeader): void {
    this.stateHeader$.next(data);
  }

  public getActuallyRoute() {
    return this._router.url;
  }
}
