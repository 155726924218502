import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContext, HttpContextToken, HttpHeaders } from '@angular/common/http';

// Models
import { Url } from '../modules/shared/models/enums';

// Services
import { StoreService } from '../modules/shared/services/store/store.service';

const typeUrl = new HttpContextToken<Url>(() => Url.default);

export function setUrl(url: Url): HttpContext {
  return new HttpContext().set(typeUrl, url);
}

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  visitorId: any;

  constructor(
    private _storeService: StoreService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.addHeaders(request));
  }

  private addHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const _type: number = request.context.get(typeUrl);
    const _headers: HttpHeaders = this.buildHeaders(_type);
    return request.clone({
      headers: _headers
    });
  }

  private buildHeaders(type: Url): HttpHeaders {
    if (type === Url.home) {
      return this.getHeadersHome();
    }
    return this.getHeadersDefault();
  }

  private getHeadersDefault(): HttpHeaders {
    let headers = new HttpHeaders({});
    return headers;
  }

  private getHeadersHome(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this._storeService.getToken()}`, 
      'VisitorId': `${this._storeService.getFinger()}`    
    });
    return headers;
  }

}