export enum Url {
  fusion,
  menu,
  home,
  nacional,
  default,
}

export enum TypeData {
  num,
  text,
  date,
  progress,
  button,
  textWithouthHover,
  hash,
  viewDocument,
  desc,
  textWithHash,
}

export enum TablesStyle {
  line,
  tab,
}

export enum TypeFilter {
  corporation = "corporation",
  departament = "departament",
  town = "town",
  commission = "commission",
  subcommission = "subcommission",
  zone = "zone",
  point = "point",
  table = "table",
  date = "date",
  comuna = "comuna",
  origin = "origin",
  method = "metodo",
}

export enum Level {
  country = "País",
  departament = "Departamento",
  town = "Municipio",
  commission = "Comisión",
  zone = "Zona",
  point = "Puesto",
  table = "Mesa",
}

export enum TypeNovedades {
  extemporaneos = 1,
  malEstado = 2,
  conTachaduras = 3,
  excluidas = 4,
  conVotosIncinerados = 5,
  menos2Firmas = 6,
  recontadaComision = 7,
  recontadaJurados = 8,
  modificadas = 9,
  sinVotacion = 10,
  blancosNulosNoMarcados = 11,
  e11NoTotalizados = 12,
  e14NoTotalizados = 13,
}

export enum TypeDocument {
  age = "AGE",
  e24 = "E24",
  e26 = "E26",
  mmv = "MMV",
  mms = "MMS",
  vot = "VOT",
  ace = "ACE",
  lis = "LIS",
}

export enum CaseTypeDocument {
  download,
  main,
  read,
}

export enum TypeIdJobMember {
  int = "INT",
  sec = "SEC",
  sop = "SOP",
}

export enum TypeNameJobMember {
  int = "JUEZ",
  sec = "SECRETARIO",
  sop = "SOPORTE",
}

export enum TypeActionMember {
  create = "CREATE",
  remove = "REMOVE",
  update = "UPDATE",
}

export enum TypeStateTimeline {
  early = "early",
  mid = "mid",
  end = "end",
}

export enum TypeCodeReponse {
  n0 = "Exitoso OK",
  n3 = "Código de proceso no válido",
  n4 = "Token no válido",
  n5 = "Fecha inválida",
  n6 = "Versión de app no válida",
  n7 = "Versión de BD no válida",
  n8 = "Comisión no registrada",
  n90 = "Comisión no existe",
  n91 = "Comisión ya licenciada",
  n94 = "Orden erróneo",
  n99 = "Orden repetido",
  n101 = "Clave inválida",
  n102 = "Código de comisión de autorizacion inválido",
  n103 = "Candidato no existe",
  n104 = "Integrante ya existe",
  n105 = "Partido Politico No Existe",
  n106 = "Integrante no existe",
  n107 = "Datos integrante inválidos",
  n108 = "El archivo no se encuentra digitalizado",
  n109 = "Datos de resolución invalidos",
  n110 = "No se encuentra información sobre la mesa",
  n111 = "La reclamación ya existe",
  n112 = "Resolución ya existe",
  n113 = "Resolución no existe",
  n114 = "Mesa no existe",
  n115 = "El tipo de Corporación no existe",
  n116 = "Miembro no registrado",
  n117 = "Miembro no pertenece a la comisión",
  n118 = "El cargo no corresponde",
  n119 = "El tipo integrante no existe",
  n121 = "Comisión conformada",
  n122 = "No se pueden ingresar mas jueces",
  n123 = "No se pueden ingresar mas secretarios",
  n124 = "Solo se puede ingresar un soporte técnico",
  n200 = "El target no existe en la tabla OPCION_PROCESAMIENTO",
  n201 = "Error en la estructura del Json de la petición del paquete.",
  n202 = "El LogPaquete no existe en la tabla LOG_PAQUETE",
  n203 = "El ID LogPaquete esta vacio en el mensaje Kafka",
  n404 = "NOT_FOUND",
  n500 = "SERVER_ERROR",
}

export const messagesTypeResponse: any = {
  0: TypeCodeReponse.n0,
  3: TypeCodeReponse.n3,
  4: TypeCodeReponse.n4,
  5: TypeCodeReponse.n5,
  6: TypeCodeReponse.n6,
  7: TypeCodeReponse.n7,
  8: TypeCodeReponse.n8,
  90: TypeCodeReponse.n90,
  91: TypeCodeReponse.n91,
  94: TypeCodeReponse.n94,
  99: TypeCodeReponse.n99,
  101: TypeCodeReponse.n101,
  102: TypeCodeReponse.n102,
  103: TypeCodeReponse.n103,
  104: TypeCodeReponse.n104,
  105: TypeCodeReponse.n105,
  106: TypeCodeReponse.n106,
  107: TypeCodeReponse.n107,
  108: TypeCodeReponse.n108,
  109: TypeCodeReponse.n109,
  110: TypeCodeReponse.n110,
  111: TypeCodeReponse.n111,
  112: TypeCodeReponse.n112,
  113: TypeCodeReponse.n113,
  114: TypeCodeReponse.n114,
  115: TypeCodeReponse.n115,
  116: TypeCodeReponse.n116,
  117: TypeCodeReponse.n117,
  118: TypeCodeReponse.n118,
  119: TypeCodeReponse.n119,
  121: TypeCodeReponse.n121,
  122: TypeCodeReponse.n122,
  123: TypeCodeReponse.n123,
  124: TypeCodeReponse.n124,
  200: TypeCodeReponse.n200,
  201: TypeCodeReponse.n201,
  202: TypeCodeReponse.n202,
  203: TypeCodeReponse.n203,
  404: TypeCodeReponse.n404,
  500: TypeCodeReponse.n500,
};

export const getLevelWithIndex = (index: number): Level => {
  switch (index) {
    case 1:
      return Level.country;
    case 2:
      return Level.departament;
    case 3:
      return Level.town;
    default:
      return Level.country;
  }
};

export const getNextLevel = (level: Level): Level => {
  switch (level) {
    case Level.country:
      return Level.departament;
    case Level.departament:
      return Level.town;
    case Level.town:
      return Level.zone;
    case Level.zone:
      return Level.point;
    case Level.point:
      return Level.table;
    default:
      return Level.country;
  }
};

export const getTypeFilterOfLevel = (level: Level): TypeFilter => {
  switch (level) {
    case Level.country:
      return TypeFilter.departament;
    case Level.departament:
      return TypeFilter.town;
    case Level.town:
      return TypeFilter.commission;
    case Level.commission:
      return TypeFilter.zone;
    case Level.zone:
      return TypeFilter.point;
    case Level.point:
      return TypeFilter.table;
    default:
      return TypeFilter.departament;
  }
};

export const getTypeFilterWithIndex = (index: number): TypeFilter => {
  switch (index) {
    case 1:
      return TypeFilter.departament;
    case 2:
      return TypeFilter.departament;
    case 3:
      return TypeFilter.town;
    default:
      return TypeFilter.departament;
  }
};

export enum DateFormat {
  monthText,
  default,
}

export enum CaseTotalVoto {
  calcularTotales,
  calcularTotalesCards,
  cargarDatosTablaVotation,
}