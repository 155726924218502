import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

// Models
import { User } from "../../models/user";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  constructor(private _jwtHelperService: JwtHelperService) {}

  public getToken(): string | null {
    return localStorage.getItem("RXNjcnV0aW5pb3NNb25pdG9yaW5nS2V5");
  }

  public setToken(token: string): void {
    localStorage.setItem("RXNjcnV0aW5pb3NNb25pdG9yaW5nS2V5", token);
  }

  public getUser(): User | null {
    let user: string | null = localStorage.getItem(
      "RXNjcnV0aW5pb3NNb25pdG9yaW5nRGF0YQ=="
    );
    if (user) {
      try {
        const user$: User = JSON.parse(user) as User;
        return user$;
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    }
    return null;
  }

  public setUser(user: User | null): void {
    let _user = JSON.stringify(user);
    localStorage.setItem("RXNjcnV0aW5pb3NNb25pdG9yaW5nRGF0YQ==", _user);
  }

  public verifyToken(type: number): boolean {
    const _token: string | null = this.getToken();
    if (_token) {
      const expiredToken: boolean = this.checkExpiredToken(_token);
      return expiredToken;
    }
    return false;
  }

  private checkExpiredToken(token: string): boolean {
    const tokenData: any = this.getDataToken();
    if (tokenData?.exp) {
      const now: number = Math.round(new Date().getTime() / 1000.0);
      const timeLeft: number = tokenData.exp - now;
      if (timeLeft >= 60) {
        return false;
      }
    }
    return true;
  }

  public getDataToken(): any {
    let tokenData: any = null;
    const token: string | null = this.getToken();
    if (token) {
      try {
        const data: any = this._jwtHelperService.decodeToken(token);
        return data;
      } catch (error) {}
    }
    return tokenData;
  }

  public logout = (): any => localStorage.clear();

  public getFinger(): string | null {
    return localStorage.getItem("VmlzaXRvcklk");
  }

  public generateFinger(): void {
    let date = new Date();
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        let visitorInformation = {
          VisitorId: result.visitorId,
          createdAt: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
        };
        localStorage.setItem(
          "VmlzaXRvcklk",
          btoa(JSON.stringify(visitorInformation))
        );
      });
  }
  
}
